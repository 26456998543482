<template>
    <div class="vstack" style="height: 100dvh">
        <div class="bg-green2 vstack py-2 px-3 text-light">
            <div class="fw-semibold">
                {{ contact?.name }}
            </div>
            <div>
                {{ contact?.number }}
            </div>
        </div>
        <Whatsapp class="whatsappMsgs flex-grow-1 h-100 overflow-y-auto position-relative rounded-0" :timestampDiff="timestampDiff" :mediaURL="mediaURL" :messages="messages" height="100%" :showLoadHistoryButton="true" />
    </div>
</template>

<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/OpusMediaRecorder.umd.js"></script>
<script type="application/javascript" src="https://cdn.jsdelivr.net/npm/opus-media-recorder@latest/encoderWorker.umd.js"></script>


<script>
import Whatsapp from './whatsapp.vue'
import api from '../services/apiService.js'


export default {
    components: {
        Whatsapp,
    },
    props: [
        'timestampDiff',
        'mediaURL',
        'i18n',
    ],
    mounted() {
        this.$nextTick(() => {
            this.loadMessages()
        })
    },
    data() {
        return {
            contact: null,
            messages: [],
            channelId: this.$route.params.channelId,
            attendanceId: this.$route.params.attendanceId,
        }
    },
    methods: {
        async loadMessages() {
            const client = this.attendance
            const resp = await api.getAttendanceMessages(this.channelId, this.attendanceId)

            console.log("resp",resp)

            if (resp.statusCode !== 200) {
                this.$emit('msg', {
                    text: 'Ocorreu um erro ao carregar as mensagens!',
                    success: false,
                })
                return
            }

            const {
                attendanceHistory: { messages, contactName, contactNumber },
            } = resp

            this.contact = { name: contactName, number: contactNumber }

            let msgs = []

            for (const el of messages) {
                if (this.timestampDiff > 100) el.timestamp -= this.timestampDiff;

                if (el.timestamp > 9999999999) el.timestamp /= 1000;

                if (el.type === 'vcard') {
                    if (!el.vcardName)
                        el.vcardName = el.body.split('FN:')[1].split('\n')[0];
                    el.vcardNumber = el.body.split('waid=')[1].split(':')[0];
                } else if(el.type === 'product_list') {
                    const product = await this.getProductByProductId(el.body?.productList?.at(0)?.productId)
                    if(product) {
                        el.body.imageURL = product.imageLink
                    }
                }

                msgs.push(el)
            }
            
            this.messages = msgs

            dateAux = null
            msgs = null
            this.scrollBottomMsgsDiv()
        },
    },
}
</script>

<style>
    .whatsappMsgs > .rounded {
        border-radius: 0 !important;
    }
</style>